@font-face {
  font-family: "Amiga Forever Pro";
  src: url("./amiga4ever_pro-webfont.woff2") format("woff2"),
    url("./amiga4ever_pro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Amiga Forever Pro 2";
  src: url("./amiga4ever_pro2-webfont.woff2") format("woff2"),
    url("./amiga4ever_pro2-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Amiga Forever";
  src: url("./amiga4ever-webfont.woff2") format("woff2"),
    url("./amiga4ever-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
