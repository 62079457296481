@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Amiga Forever Pro";
  src: url(/static/media/amiga4ever_pro-webfont.aaa60851.woff2) format("woff2"),
    url(/static/media/amiga4ever_pro-webfont.7b8aeeb1.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Amiga Forever Pro 2";
  src: url(/static/media/amiga4ever_pro2-webfont.0b40f7c1.woff2) format("woff2"),
    url(/static/media/amiga4ever_pro2-webfont.6360e215.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Amiga Forever";
  src: url(/static/media/amiga4ever-webfont.93a508af.woff2) format("woff2"),
    url(/static/media/amiga4ever-webfont.1d089e1a.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bangers";
  src: url(/static/media/bangers-v13-latin-regular.607fafcc.woff2) format("woff2"),
    url(/static/media/bangers-v13-latin-regular.e4fe0722.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Best Friends";
  src: url(/static/media/best-friends-font.e907b484.woff2) format("woff2"),
    url(/static/media/best-friends-font.c60e5e29.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bungee";
  src: url(/static/media/bungee-v6-latin-regular.69670d81.woff2) format("woff2"),
    url(/static/media/bungee-v6-latin-regular.7dabaf17.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Chakra Petch";
  src: url(/static/media/ChakraPetch-Regular.fa182136.woff2) format("woff2"),
    url(/static/media/ChakraPetch-Regular.287c8b8e.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fredoka One";
  src: url(/static/media/fredokaone-regular-webfont.432325be.woff2) format("woff2"),
    url(/static/media/fredokaone-regular-webfont.9d506e86.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* 
html {
  box-sizing: border-box;
  font-size: 15px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.MuiExpansionPanel-root:before {
  height: 0px !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.game-button {
  border: none;
  background-color: white;
  padding: 0.5rem 1rem;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  color: #3165ec;
  font: 20px "Fredoka One";
  transition: background 100ms ease-in-out;
}

.game-button--selected {
  background-color: #3165ec;
  color: white;
}

.game-button:hover,
.game-button:focus,
.game-button:active {
  background-color: #3165ec;
  color: white;
} */

